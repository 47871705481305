import { Injectable } from '@angular/core';
import { createStore, setProp, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  getActiveEntity,
  hasEntity,
  resetActiveId,
  selectActiveEntity,
  selectActiveId,
  setActiveId,
  updateEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { excludeKeys, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { filter, map, Observable } from 'rxjs';

import {
  ISMetricEnum,
  ISNumericMetricEnum,
  ISStoreEnum,
  ISTacticKey,
  ISUser,
} from '@ess/integrated-search/shared/utils';
import {
  ISTacticChartSeries,
  ISTacticMetricTab,
  ISTacticMetricTabConfig,
  ISTacticMetricTabs,
  ISTacticsInitialChartDefsHelper,
} from '@ess/integrated-search/tactics/shared/utils';
import { SharedActiveEntitiesStore, SharedStoreValue } from '@ess/shared/utils/models';

interface StoreProps {
  user: string | null;
}

type Store = SharedActiveEntitiesStore<ISTacticMetricTabs, number, StoreProps>;

@Injectable()
export class IntegratedSearchTacticsDataAccessChartsRepository {
  readonly activeProject$: Observable<number | undefined>;

  private readonly __store: Store;

  get activeEntity(): ISTacticMetricTabs | undefined {
    return this.__store.query(getActiveEntity());
  }

  constructor() {
    this.__store = this.__createStore();
    this.__persistState();

    this.activeProject$ = this.__store.pipe(selectActiveId());
  }

  getTabsConfig$(key: ISTacticKey): Observable<ISTacticMetricTab> {
    return this.__store.pipe(
      selectActiveEntity(),
      filter(Boolean),
      map((config: ISTacticMetricTabs) => config[key]),
    );
  }

  setActiveUser(user: ISUser): void {
    const currentUser: string | null = this.__store.getValue().user;
    if (!currentUser || currentUser === user.email) {
      this.__store.update(setProp('user', user.email));
    } else {
      this.__store.update(setProp('user', user.email), deleteAllEntities());
    }
  }

  setActiveProject(projectId: number | null): void {
    if (projectId) {
      if (this.__store.query(hasEntity(projectId))) {
        this.__store.update(setActiveId(projectId));
      } else {
        this.__store.update(addEntities(this.__getInitialState(projectId)), setActiveId(projectId));
      }
    } else {
      this.__store.update(resetActiveId());
    }
  }

  updateTabsConfig(key: ISTacticKey, chartConfig?: ISTacticMetricTabConfig[]): void {
    const tabsConfig = this.activeEntity;
    if (!tabsConfig) return;

    let updatedConfig: ISTacticMetricTabs = { ...tabsConfig };
    if (chartConfig) {
      updatedConfig = {
        ...tabsConfig,
        [key]: { tabs: chartConfig },
      };
    }

    this.__store.update(updateEntities(tabsConfig.projectId, updatedConfig));
  }

  private __createStore(): Store {
    return createStore(
      { name: ISStoreEnum.CHART_TABS },
      withProps<StoreProps>({
        user: null,
      }),
      withEntities<ISTacticMetricTabs, 'projectId'>({ idKey: 'projectId' }),
      withActiveId(),
    );
  }

  private __persistState(): void {
    persistState(this.__store, {
      key: 'integratedSearchTacticsDataAccess',
      storage: localStorageStrategy,
      source: () => this.__store.pipe(excludeKeys(['ids', 'activeId'])),
      preStoreInit: (value: SharedStoreValue<Store>) => this.__preStoreInitChecks(value),
    });
  }

  private __preStoreInitChecks(value: SharedStoreValue<Store>): SharedStoreValue<Store> {
    const entities: Record<number, ISTacticMetricTabs> = value.entities;
    Object.entries(entities).forEach(([key, value]) => (entities[+key] = this.__checkMetrics(value)));
    return value;
  }

  private __checkMetrics(tabs: ISTacticMetricTabs): ISTacticMetricTabs {
    const metrics = Object.values(ISNumericMetricEnum);
    const archive: { [key: string]: ISTacticChartSeries } = {
      conversion_rate: ISMetricEnum.PPC_CONVERSION_RATE,
      conversions_value: ISMetricEnum.CONVERSIONS_VALUE,
      roas: ISMetricEnum.PPC_ROAS,
      conversions: ISMetricEnum.PPC_CONVERSIONS,
      ctr: ISMetricEnum.PPC_CTR,
      impressions_ppc: ISMetricEnum.PPC_IMPRESSIONS,
      impressions_seo: ISMetricEnum.SEO_IMPRESSIONS,
      spend: ISMetricEnum.PPC_SPEND,
      combined: ISMetricEnum.COMBINED_IMPRESSIONS,
      ppc: ISMetricEnum.PPC_IMPRESSIONS,
      seo: ISMetricEnum.SEO_IMPRESSIONS,
    };

    const checkMetric = (metric: ISTacticChartSeries): ISTacticChartSeries | null => {
      const lowercased = metric.toLowerCase() as ISNumericMetricEnum;
      return metrics.includes(lowercased) ? lowercased : (archive[lowercased] ?? null);
    };

    Object.keys(tabs).forEach((path) => {
      const tabsConfig: ISTacticMetricTabConfig[] | undefined = tabs[path as ISTacticKey].tabs;
      if (tabsConfig?.length) {
        tabsConfig.forEach((tab) =>
          tab.charts.forEach((chart) => {
            chart.metric_1 = chart.metric_1 ? checkMetric(chart.metric_1) : null;
            chart.metric_2 = chart.metric_2 ? checkMetric(chart.metric_2) : null;
          }),
        );
      }
    });

    return tabs;
  }

  private __getInitialState(projectId?: number): ISTacticMetricTabs {
    return ISTacticsInitialChartDefsHelper.getInitialState(projectId);
  }
}
